import { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import MuaHangPiEn from "./mua-hang-pi";
import MuaHangPiVi from "./mua-hang-pi-in";
import ThanhToanPiVi from "./thanhtoan-vi";
import ThanhToanPiEn from "./thanhtoan";

import ThanhToanPiGlobal from "./thanhtoan-global";
import MuaHangPiGlobal from "./mua-hang-pi-global";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { initMetaPixel, trackPageView } from "./utils/metaPixel";

const firebaseConfig = {
  apiKey: "AIzaSyDzoFjRBV5cHmblBCC1l9nTqU-1E9YEul8",
  authDomain: "pichamall.firebaseapp.com",
  projectId: "pichamall",
  storageBucket: "pichamall.appspot.com",
  messagingSenderId: "1081583954985",
  appId: "1:1081583954985:web:d4a79a0a7a9ce69e788038",
  measurementId: "G-F47C72ZVXX",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  useEffect(() => {
    const pixelId = "952811763271292"; // Replace with your Meta Pixel ID
    initMetaPixel(pixelId);
    trackPageView(); // Track page view when the app is loaded
  }, []);

  return (
    <>
      <Router>
        <main className="app">
          <Switch>
            {/* vi */}
            <Route exact path="/" component={MuaHangPiVi} />
            <Route exact path="/muahangpi" component={MuaHangPiVi} />
            <Route exact path="/thanhtoanπ" component={ThanhToanPiVi} />

            {/* en */}
            <Route exact path="/en" component={MuaHangPiEn} />
            <Route exact path="/en/muahangpi" component={MuaHangPiEn} />
            <Route exact path="/en/thanhtoanπ" component={ThanhToanPiEn} />

            {/* global - vietnam version */}
            <Route exact path="/global" component={MuaHangPiGlobal} />
            <Route exact path="/global/muahangpi" component={MuaHangPiGlobal} />
            <Route
              exact
              path="/global/thanhtoanπ"
              component={ThanhToanPiGlobal}
            />
          </Switch>
        </main>
      </Router>
    </>
  );
}

export default App;
